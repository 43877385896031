import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import Recaptcha from "react-google-recaptcha";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY || "dev-key";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptcha = (value) => {
    this.setState({ "g-recaptcha-response": value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state["g-recaptcha-response"] === undefined) {
      alert("Recatcha must be successful.");
      return;
    }
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout title="Kontakt" pageKeywords="Kontakt">
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Kontakt</h1>
              <p>
                Det finns många sätt att komma i kontakt med oss. Personlig
                kontakt har alltid varit det bästa och kommer alltid att vara
                det mest effektiva. Ni är alltid välkomna att besöka oss och
                givetvis kommer vi gärna och besöker er.
              </p>
              <div className="columns is-desktop is-vcentered">
                <div className="column">
                  <p>
                    Ni når oss på e-post och telefon, eller så kan ni använda
                    formuläret.
                  </p>
                </div>
                <div className="column">
                  <p>
                    E-post:{" "}
                    <OutboundLink href="mailto:info@akpol.com">
                      info@akpol.com
                    </OutboundLink>
                  </p>
                </div>
                <div className="column">
                  <p>
                    Telefon:{" "}
                    <OutboundLink href="tel:+46705913831">
                      +46 70 591 38 31
                    </OutboundLink>
                  </p>
                </div>
              </div>
              <form
                name="contact"
                method="post"
                action="/kontakt/tack"
                data-netlify="true"
                data-netlify-recaptcha="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"name"}>
                    Ditt namn
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"text"}
                      name={"name"}
                      onChange={this.handleChange}
                      id={"name"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"email"}>
                    E-post
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"email"}
                      name={"email"}
                      onChange={this.handleChange}
                      id={"email"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"message"}>
                    Meddelande
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={"message"}
                      onChange={this.handleChange}
                      id={"message"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <Recaptcha
                    ref="recaptcha"
                    sitekey={RECAPTCHA_KEY}
                    onChange={this.handleRecaptcha}
                  />
                </div>
                <div className="field">
                  <button className="button is-link" type="submit">
                    Skicka
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
